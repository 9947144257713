.custom-switch {
  min-height: 26px;
  min-width: 62px;
  padding: 0;
  .custom-control-label::after {
    width: 22px!important;
    height: 22px!important;
    border-radius: 22px;
    transition: all .15s ease-in-out;
    top: 2px;
    left: 2px;
    background-color: white;
  }
  .custom-control-label::before {
    width: 62px!important;
    height: 26px!important;
    border-radius: 26px;
    left: 0;
    top: 0;
    border: #ccc;
    background: #ccc;
  }
  .custom-control-input:checked~.custom-control-label::before{
    border: $theme-color !important;
    background: $theme-color !important;
  }
  input:focus,.custom-control-input:focus~.custom-control-label::before{
    box-shadow:none !important;
  }
  .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(36px);
  }
  
  
}
