
a {
  text-decoration: none;
}
.modal-footer .btn-close {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  background: transparent !important;
}

.modal-footer .btn-close:hover {
  color: #fff !important;
  border-color: #dc3545 !important;
  background: #dc3545 !important;
}

.form-control[readonly] {
  color: #000 !important;
}

.modal-body .form-control[readonly] {
  color: #fff !important;
}
.modal-body p {
  color: #9a9a9a;
}

.bg-success {
  border: 1px solid #28a745 !important;
  color: #28a745 !important;
}
.bg-danger {
  border: 1px solid #dc3545 !important;
  color: #dc3545 !important;
}
.status {
  padding: 0.35em 0.65rem;
  border-radius: 50rem !important;
  background-color: transparent !important;
}
.site-logo-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.settings-form .form-control-file::before {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto;
}

.form-control-file {
  color: transparent !important;
}
.form-control-file::-webkit-file-upload-button {
  visibility: hidden;
}
.form-control-file.videoInput::before {
  content: "Upload Video";
}
.form-control-file::before {
  content: "Select an image";
  color: white;
  display: inline-block;
  background: #888888;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 20px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select-: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}
.banner-table.table th,
.banner-table.table td {
  vertical-align: top;
}
.banner-textarea {
  width: 100%;
  height: 70px;
}
.form-control-file.table-form::before {
  width: 100%;
}
.form-control-file:hover::before {
  border-color: black;
}
.form-control-file:active {
  outline: 0;
}
.form-control-file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.btn-action {
  border: 1px solid #ffff;
  background-color: transparent;
  margin: 0;
  font-weight: 400;
  padding: 8px 5px;
  cursor: pointer;
  border-radius: 5px;
}
.refund-modal .modal-content {
  background-color: #ffff;
}
.refund-modal .modal-content label {
  color: black;
  padding-left: 35px;
}
.refund-modal .form-check input[type="checkbox"],
.form-check-radio input[type="radio"] {
  opacity: 1;
  position: absolute;
  visibility: visible;
  width: 50px;
  height: 25px;
}
.refund-modal .form-check {
  display: flex;
  align-items: center;
}
.white {
  color: #fff;
}
.btn-action .fa-eye {
  color: #f15927;
}
.btn-action .fa-at {
  color: blue;
}
.btn-action .fa-envelope {
  color: #ff8d00;
}
.btn-action .fa-edit {
  color: #0083ad;
}

.btn-action .fa-trash {
  color: #c92626;
}
.btn-action .fa-bars {
  color: #87cb16;
}

/* .table .btn {
    transition: all .15s ease-in;
    border: 1px solid #fff!important;
    border-top-right-radius: 1px solid #fff !important;
    border-bottom-right-radius: 1px solid #fff !important;
    border-top-left-radius: 1px solid #fff !important;
    border-bottom-left-radius: 1px solid #fff !important;
    margin: 0 5px 0 0;
    opacity: 1 !important;
    background-color: transparent !important;
} */
.card-collection {
  color: white;
}

.modal-footer .btn-save {
  color: #28a745 !important;
  border-color: #28a745 !important;
  background: transparent !important;
}
.update-pic-btn {
  min-width: 106px;
}

.modal-content label {
  color: #fff;
}

.modal-footer .btn-save:hover {
  color: #fff !important;
  border-color: #28a745 !important;
  background: #28a745 !important;
}

.accordion-toggle:after {
  /* symbol for "opening" panels */
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  /* adjust as needed, taken from bootstrap.css */
  float: right;
  /* adjust as needed */
  color: grey;
  /* adjust as needed */
}

.accordion-toggle:not(.collapsed):after {
  /* symbol for "collapsed" panels */
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  /* adjust as needed, taken from bootstrap.css */
}
.accordion ul li {
  margin-bottom: 70px;
}
.activity-table tbody > tr > td {
  padding: 18px !important;
}

.accordion .card-header {
  color: white;
  padding: 15px;
  font-weight: bolder;
}

.accordion .card-body p {
  color: white;
}

.accordion .card-body span {
  color: white;
}

.accordion .card-body input {
  color: rgb(39, 37, 37);
  opacity: 0.8;
}

.accordion button {
  color: white;
}
select {
  width: 100%;
  background: #ffffff;
  color: grey;
  padding: 8px;
  outline: none;
  border: 1px solid #cccccc !important;
}

.filter-card select {
  border: 1px solid #d2d6da !important;
}

select > option:checked {
  background-color: #f1ba29 !important;
  color: #fff;
}

select > option::-webkit-scrollbar {
  background-color: #f1ba29 !important;
  color: #fff;
}

.modal-footer .btn-done {
  color: #28a745 !important;
  border-color: #28a745 !important;
  background: transparent !important;
}

.modal-footer .btn-done:hover {
  color: #fff !important;
  border-color: #28a745 !important;
  background: #28a745 !important;
}

.uploaded-img {
  width: 80px;
  height: 80px;
  margin: 12px;
}

/* .img-file {
    color: #dee2e6 !important;
    opacity: 0.8 !important;
} */

.table-bigboy .img-container {
  width: 180px;
  height: 190px;
  overflow: hidden;
  display: block;
  border-radius: 2px !important;
}

/* .name-email .form-group {
    flex: 0 0 50%;
} */

.nft-flex .form-group {
  flex: 0 0 50%;
}

.background-color-card {
  background-color: #c30808 !important;
}

.background-color-card-body {
  background-color: black !important;
}

.label-font {
  font-weight: 600;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  vertical-align: top;
}

.field-value {
  font-size: 14px;
}

.modal .modal-content .modal-footer {
  border-top: none;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  justify-content: flex-end;
}

.select-bg-wrapper select {
  background-color: white;
  color: black;
}

.sidebar-wrapper::-webkit-scrollbar {
  width: 0;
}
.sidebar .nav::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #f15927;
  border-radius: 10px;
}

/* Permissions / Roles */
.check-inline .fancy-checkbox {
  width: 30%;
  font-weight: 700;
  margin-bottom: 10px;
  vertical-align: top;
}

.check-inline label.fancy-checkbox {
  font-weight: normal;
}

.check-inline .fancy-checkbox.selectall-checkbox-holder {
  width: 100%;
}
.card-login {
  background: rgba(255, 255, 255, 1);
}
.card-login .card-header {
  background-color: transparent;
}
.card-login label {
  color: #000;
}
.full-page:after,
.full-page:before {
  display: none;
}
.card-login a {
  color: #f15927;
}
.card-login .card-footer .btn-info,
.card-login .card-footer .btn-info:focus,
.card-login .card-footer .btn-info:not(:disabled):not(.disabled):active {
  background: #f15927;
  border-color: #f15927;
}
.card-login .card-footer .btn-info:hover {
  border-color: #f15927;
  color: #f15927;
}
.contact-query-modal .msg-body {
  word-break: break-all;
  margin-top: -5px;
}
.chart-row {
  height: 100%;
  /* align-items: center; */
}
.chart-row canvas {
  height: 100% !important;
}
.form-password-eye-box {
  position: relative;
}
.form-password-eye {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  color: #96a1f0;
}
.form-password-eye-box .form-control {
  padding-right: 40px;
}
.profile-admin-form .form-password-eye {
  top: 7px;
}
.staff-modal ::-webkit-input-placeholder,
.staff-modal :-ms-input-placeholder,
.staff-modal ::placeholder {
  color: #9e9e9ea8 !important;
}
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
}
.table-big-boy .btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger:not(:disabled):not(.disabled):active,
.table-big-boy .btn-success.active,
.btn-success:active,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled):active {
  background-color: transparent;
}
.main-panel .dropdown-item.active {
  color: #fff;
}
.user-profile-img img {
  width: 32px;
  margin-right: 0;
  border-radius: 20px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
}
.extract-file-wrap a {
  min-width: 150px;
  margin-bottom: 0;
}
.table-bigboy > thead > tr > th {
  white-space: nowrap;
}
/* Select z-index */
.filter-card.customCard {
  overflow: visible !important;
}
.custom__menu-list {
  max-height: 180px !important;
}
.btnGroup {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: end;
}
.btnGroup button {
  width: 40%;
  padding: 7px 7px;
}
.add-resellers-input .form-control {
  height: 37px;
}
.btnGroup label {
  margin-bottom: 13px;
}
.btnGroup button:first-child {
  margin-right: 10px;
}
.modal-form-wrapper label {
  padding-left: 0 !important;
  display: block;
}
.modal-form-wrapper input,
.modal-form-wrapper textarea {
  padding: 2px 10px;
  font-size: 18px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 100%;
}
.modal-form-wrapper textarea {
  height: 110px;
}
/* .modal-content {
  width: 700px;
} */
.modal-list li {
  word-break: break-all;
}
.grey-border {
  border-color: #888888 !important;
}
.reseller-select-all  .css-g1d714-ValueContainer {
  max-height: 200px;
  overflow-y: auto;
}
.reseller-add-product .form-group {
  margin-bottom: 0;
}
.select-form-wrapper {
  margin-right: 20px;
  display: flex;
    max-width: 325px;
    width: 100%;
    align-items: center;
    justify-content: end;
}
.remove-margin{
  margin-bottom: 0 !important;
  margin-right: 20px;
}
.pagination-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination-wrapper .select-form-wrapper select{
  padding: 0;
  width: 50px;
  font-size: 0.9rem;
}
.pagination-wrapper .select-form-wrapper .form-group{
  margin-bottom: 0;
}
@media (max-width: 1490px) {
  .form-control-file::before {
    padding: 8px 20px;
  }
}
@media (max-width: 991px) {
  .main-panel .nav-link {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .check-inline .fancy-checkbox {
    width: 48%;
  }
}

@media screen and (max-width: 575px) {
  .check-inline .fancy-checkbox {
    width: 100%;
    margin-right: 0;
  }
  .extract-file-wrap {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .extract-file-wrap input {
    margin-bottom: 5px;
  }
  .extract-file-wrap a {
    width: 100%;
  }
}

.search-wrap {
  overflow: hidden;
}
.search-no-wrap {
  overflow: visible !important;
}

.search-wrap .btn i:first-child {
  margin-right: 0;
}

.search-wrap .search-form {
  margin: 0 10px 10px 0;
  width: 100%;
  max-width: calc(100% - 80px);
}

.label {
  font-weight: normal;
}

.label-info {
  /* background-color: #c92626; */
  background-color: transparent;
  border: 1px solid #f15927;
  color: #f15927 !important;
  margin-bottom: 10px !important;
  padding: 2px 4px;
  border-radius: 2px;
}

.accordion input {
  background-color: transparent;
  border: 1px solid #d2d6da;
  padding: 4px;
  margin-bottom: 10px;
}

/* .accordion .img-file {
    width: 25%;
} */
.img-file {
  /* color: #3a3a3a!important; */
  color: #3a3a3a !important;
  border: none !important;
}
.img-file::-webkit-file-upload-button {
  visibility: hidden;
}
.img-file::before {
  content: "Select an image";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select-: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.license-file::before {
  content: "Select Excel File";
}
.extract-file::before {
  content: "Select File";
}

.img-file:hover::before {
  border-color: black;
}
.img-file:active {
  outline: 0;
}
.img-file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.filter-card select {
  color: #000000 !important;
}
/* .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: transparent;
    border: 1px solid #585858;
    color: #fff;
    opacity: 0.6;
    border-color: #9A9A9A;
    cursor: not-allowed;
} */

.table.table-scroll {
  overflow: hidden;
  overflow-x: auto;
}
.banner-table {
  min-width: 800px;
}
.customer_select {
  background-color: #3a3a3a;
  color: white;
}
.price-list .react-datepicker__header {
  background: #f15927;
}
.price-list .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f15927;
}
.reseller-special-area {
  background: #f15927;
  padding: 20px;
  color: white;
}
.modal .css-1dimb5e-singleValue {
  color: #a4a4a4;
}
.modal .css-13cymwt-control, .modal .css-16xfy0z-control {
  background: transparent;
  background-color: transparent;
  color: white;
  border: 1px solid #585858;
  min-height: 40px
}

/** Import  Modal Css**/
header#chakra-modal--header-rsi {
  background-color: #f15927;
}
button.chakra-button, button.chakra-button:hover {
  background: #f15927;
}
button.chakra-button.css-1lcexi1 {
  background: red;
  color: white;
  border-color: red;
}
.css-j9zkf3 {
  background-image: repeating-linear-gradient(0deg, #f15927, #f15927 10px, var(--chakra-colors-transparent) 10px, var(--chakra-colors-transparent) 20px, #f15927 20px), repeating-linear-gradient(90deg, #f15927, #f15927 10px, var(--chakra-colors-transparent) 10px, var(--chakra-colors-transparent) 20px, #f15927 20px), repeating-linear-gradient(180deg, #f15927, #f15927 10px, var(--chakra-colors-transparent) 10px, var(--chakra-colors-transparent) 20px, #f15927 20px), repeating-linear-gradient(270deg, #f15927, #f15927 10px, var(--chakra-colors-transparent) 10px, var(--chakra-colors-transparent) 20px, #f15927 20px) !important;
}
.cui-steps__horizontal-step span {
  color: white;
  border-color: #333333;
}
.accordion .accordion-header button {
  color: #f15927;
  font-weight: 600;
  font-size: 19px;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: transparent;
}
.accordion-button:not(.collapsed) {
  color: #f15927;
  background-color: #cfe2ff00;
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 #dee2e600;
}
.form-check-input{
  height: 1.5rem;
  width: 1.5rem;
}